import { SVGProps } from 'react'

export const FacebookIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.60361 21.9667H13.4262V12.3887H16.0991L16.3865 9.18402H13.4334V7.35896C13.4334 6.60451 13.5843 6.30272 14.3172 6.30272H16.3865V2.96875H13.7351C10.8898 2.96875 9.60361 4.21899 9.60361 6.61888V9.17684H7.61328V12.4246H9.60361V21.9667Z"
      fill="currentColor"
    />
  </svg>
)
