import { AthleteDatasourcesQuery } from 'api/integrations/storyblok/generated/cms'
import { AthleteListingQuery } from 'apollo/generated/graphqlClient'
import currency from 'currency.js'
import { cn } from 'lib/utils'
import { isNil } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { NA } from 'constants/common'
import { GROUP_SERVICE_FEE } from 'constants/common/booking'
import { resolveRoute, Route } from 'constants/common/routes'
import { PrimaryText, SecondaryText } from 'components/common'
import { FlagIcon, LocationIcon, RunIcon, UserIcon } from 'components/icons'
import RatedStarIcon from 'components/icons/RatedStarIcon'
import UnratedStarIcon from 'components/icons/UnratedStarIcon'
import { buttonVariants } from 'components/ui/button'

interface Props {
  athlete: AthleteListingQuery['athleteListing']['items'][0]
  athleteDatasources: AthleteDatasourcesQuery
}

export const AthleteCard = ({ athlete, athleteDatasources }: Props) => {
  const collegeName = athleteDatasources?.colleges?.items.find(
    (college) => college.value === athlete.college,
  )?.name
  const displayedCollege = collegeName === 'Other' ? athlete.otherCollege : collegeName

  const route = resolveRoute({
    route: Route.Athlete,
    pathParams: { slug: athlete.slug },
  })

  const getSport = (sportValue?: string | null) =>
    sportValue
      ? athleteDatasources.sports?.items.find((sport) => sport.value === sportValue)?.name
      : NA

  const { isFullyBooked, reviewScore } = athlete

  return (
    <Link href={route} className="grid w-full">
      <div className="col-span-1 xl:col-auto bg-slate-50 rounded-xl md:rounded-[20px] sm:p-6 p-5 cursor-pointer hover:scale-105 ease-in-out duration-300 hover:bg-slate-100">
        <div className="w-full h-full sm:flex sm:flex-col grid grid-cols-2">
          {athlete.profilePicUrl ? (
            <div className="sm:w-full relative sm:aspect-w-1 sm:aspect-h-1 h-[104px] w-[104px] justify-self-end">
              <Image
                src={athlete.profilePicUrl}
                alt="Picture of the athlete"
                fill
                className={cn(
                  'object-cover rounded-xl bg-white md:rounded-2xl',
                  isFullyBooked && 'opacity-30',
                )}
              />
              {isFullyBooked && (
                <div>
                  <div className="absolute text-sm font-semibold px-2.5 h-8 flex items-center rounded-md text-red-600 uppercase bg-red-50 right-2.5 bottom-2.5">
                    Available soon
                  </div>
                </div>
              )}
            </div>
          ) : (
            <div className="w-full relative aspect-w-1 aspect-h-1 rounded-xl md:rounded-[20px] overflow-hidden bg-slate-900">
              <UserIcon className="w-1/2 h-1/2 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-slate-100 shrink-0" />
            </div>
          )}

          <div className="flex flex-col gap-3 row-start-1 row-end-4 sm:mb-3">
            <div className="relative sm:text-xl text-base leading-[150%] font-semibold font-outfit text-slate-900 text-left sm:mt-6">
              {athlete.name}
            </div>
            <div className="flex flex-col gap-2.5">
              <div className="flex flex-row">
                <FlagIcon className="mr-1.5 w-5 h-5 shrink-0" />
                <div className="relative text-sm leading-[150%] line-clamp-2 font-medium font-text-sm text-slate-900">
                  {displayedCollege}
                </div>
              </div>
              <div className="flex flex-row">
                <RunIcon className="mt-0.5 mr-1.5 w-5 h-5 text-brand-slate shrink-0" />
                <div>
                  <span className="text-sm font-medium text-slate-900">
                    {getSport(athlete.sport)}
                  </span>
                  {athlete.position && athlete.position !== NA && (
                    <span className="text-sm font-light">, {athlete.position}</span>
                  )}
                </div>
              </div>
              {athlete.secondarySport?.sport && (
                <div className="flex flex-row">
                  <RunIcon className="mt-0.5 mr-1.5 w-5 h-5 text-brand-slate shrink-0" />
                  <div>
                    <span className="text-sm font-medium text-slate-900">
                      {getSport(athlete.secondarySport?.sport)}
                    </span>
                    {athlete.secondarySport?.position &&
                      athlete.secondarySport?.position !== NA && (
                        <span className="text-sm font-light">
                          , {athlete.secondarySport?.position}
                        </span>
                      )}
                  </div>
                </div>
              )}
              {athlete.secondarySport2?.sport && (
                <div className="flex flex-row">
                  <RunIcon className="mt-0.5 mr-1.5 w-5 h-5 text-brand-slate shrink-0" />
                  <div>
                    <span className="text-sm font-medium text-slate-900">
                      {getSport(athlete.secondarySport2?.sport)}
                    </span>
                    {athlete.position && athlete.position !== NA && (
                      <span className="text-sm font-light">
                        , {athlete.secondarySport2?.position}
                      </span>
                    )}
                  </div>
                </div>
              )}
              <div className="flex flex-row">
                <LocationIcon className="mr-1.5 w-5 h-5 text-brand-slate shrink-0" />
                <div className="relative text-sm leading-[150%] line-clamp-2 font-medium font-text-sm-font-medium text-slate-900">
                  {athlete.trainingSite}
                </div>
              </div>
              <div className="flex items-center sm:hidden sm:invisible">
                {reviewScore && !isNil(reviewScore.averageRating) ? (
                  <>
                    <RatedStarIcon className="mr-2 h-5 w-5 text-yellow-400 shrink-0" />
                    <PrimaryText className="text-sm mr-1">
                      {reviewScore.averageRating.toFixed(1)}
                    </PrimaryText>
                    <SecondaryText className="text-sm ml-1">{`(${reviewScore.reviewCount})`}</SecondaryText>
                  </>
                ) : (
                  <>
                    <UnratedStarIcon className="mr-2 h-5 w-5 text-neutral-300 shrink-0" />
                    <SecondaryText className="text-slate-600 font-normal text-xs">
                      Not rated
                    </SecondaryText>
                  </>
                )}
              </div>
            </div>
          </div>
          <div
            className={cn(
              'w-full mt-4 relative flex items-center justify-end text-slate-900 font-text-base-font-light',
              'sm:mt-auto sm:justify-between',
              isFullyBooked && 'row-start-4 row-end-5 col-start-1 col-end-3',
            )}
          >
            <div className="hidden invisible sm:flex sm:items-center sm:visible">
              {reviewScore && !isNil(reviewScore.averageRating) ? (
                <>
                  <RatedStarIcon className="mr-1.5 h-5 w-5 text-yellow-400 shrink-0" />
                  <PrimaryText className="text-xl">
                    {reviewScore.averageRating.toFixed(1)}
                  </PrimaryText>
                </>
              ) : (
                <>
                  <UnratedStarIcon className="mr-1.5 h-5 w-5 text-neutral-300 shrink-0" />
                  <SecondaryText className="text-slate-600 font-normal text-xs">
                    Not rated
                  </SecondaryText>
                </>
              )}
            </div>
            {isFullyBooked ? (
              <Link
                href={route}
                className={cn(
                  buttonVariants({ colorType: 'mint', size: 'xl' }),
                  'uppercase text-base font-semibold w-full sm:w-auto',
                )}
              >
                Notify me
              </Link>
            ) : (
              <div className="relative leading-[150%] font-medium sm:text-xl text-base">
                from {currency(athlete.minPrice).add(GROUP_SERVICE_FEE).format()}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  )
}
