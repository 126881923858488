export const RESERVATION_DURATION_MINUTES = 60

export const MEMBERSHIP_DISCOUNT = 10

export const GROUP_SESSION_DISCOUNT = 40

export const FIXED_SERVICE_FEE = 20

export const GROUP_SERVICE_FEE = (FIXED_SERVICE_FEE / 100) * (100 - GROUP_SESSION_DISCOUNT)

export const MINIMUM_GROUP_SESSION_CAPACITY = 2
export const MAXIMUM_GROUP_SESSION_CAPACITY = 6

export const MEMBERSHIP_CHARGE_DAYS_BEFORE_SESSION = 4
