import React, { SVGProps } from 'react'

const UnratedStarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    {...props}
  >
    <path
      d="M15.9998 24.1106L7.77045 28.4373L9.34245 19.2733L2.67578 12.7839L11.8758 11.4506L15.9904 3.11328L20.1051 11.4506L29.3051 12.7839L22.6384 19.2733L24.2104 28.4373L15.9998 24.1106Z"
      stroke="currentColor"
      strokeWidth="2.4"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default UnratedStarIcon
