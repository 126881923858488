import React, { SVGProps } from 'react'

const RatedStarIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="32"
    height="33"
    viewBox="0 0 32 33"
    fill="none"
    {...props}
  >
    <path
      d="M10.9923 10.2309L2.48564 11.4642L2.33497 11.4949C2.10689 11.5554 1.89897 11.6754 1.73243 11.8426C1.56589 12.0098 1.44671 12.2182 1.38706 12.4465C1.3274 12.6749 1.32941 12.9149 1.39288 13.1422C1.45634 13.3695 1.579 13.5759 1.74831 13.7402L7.91097 19.7389L6.45764 28.2122L6.44031 28.3589C6.42635 28.5948 6.47533 28.8302 6.58224 29.0409C6.68915 29.2516 6.85015 29.4302 7.04875 29.5583C7.24736 29.6863 7.47643 29.7593 7.7125 29.7697C7.94858 29.7801 8.18319 29.7276 8.39231 29.6176L16.0003 25.6176L23.591 29.6176L23.7243 29.6789C23.9444 29.7656 24.1836 29.7922 24.4173 29.7559C24.6511 29.7197 24.8709 29.6219 25.0544 29.4726C25.2379 29.3233 25.3784 29.1279 25.4614 28.9064C25.5445 28.6849 25.5671 28.4453 25.527 28.2122L24.0723 19.7389L30.2376 13.7389L30.3416 13.6256C30.4902 13.4426 30.5876 13.2235 30.6239 12.9906C30.6603 12.7577 30.6342 12.5194 30.5484 12.2999C30.4626 12.0803 30.3202 11.8875 30.1356 11.7409C29.951 11.5944 29.7309 11.4993 29.4976 11.4656L20.991 10.2309L17.1883 2.52423C17.0783 2.30095 16.9079 2.11292 16.6966 1.98144C16.4852 1.84996 16.2412 1.78027 15.9923 1.78027C15.7434 1.78027 15.4994 1.84996 15.2881 1.98144C15.0767 2.11292 14.9063 2.30095 14.7963 2.52423L10.9923 10.2309Z"
      fill="currentColor"
    />
  </svg>
)

export default RatedStarIcon
