import React from 'react'
import { cn } from 'lib/utils'
import Link from 'next/link'
import { FACEBOOK_URL, INSTAGRAM_URL, OFFSEASON_SUPPORT_EMAIL } from 'constants/common'
import { Route } from 'constants/common/routes'
import ExternalLink from 'components/common/ExternalLink'
import { InstagramIcon } from 'components/icons'
import { FacebookIcon } from 'components/icons/FacebookIcon'
import OffseasonLogo from 'components/icons/OffseasonLogo'
import { CONTACT_SECTION_ID } from 'components/landing-page/constants'
import { buttonVariants } from 'components/ui/button'

const LINKS = [
  {
    title: 'Terms of Service',
    href: Route.Terms,
  },
  {
    title: 'Privacy Policy',
    href: Route.PrivacyPolicy,
  },
  {
    title: 'Accessibility Statement',
    href: Route.AccessibilityStatement,
  },
  {
    title: 'Cancellation Policy',
    href: Route.CancellationPolicy,
  },
]

const Footer = () => (
  <div
    id={CONTACT_SECTION_ID}
    className="bg-slate-900 flex flex-col items-center justify-center lg:py-20 lg:px-24 md:px-16 p-12 box-border gap-[48px]"
  >
    <div className="w-full flex xl:flex-row flex-col items-center justify-start xl:justify-between box-border max-w-[1640px] lg:p-14 sm:p-8">
      <div className="flex flex-col flex-1 justify-start gap-4 items-center xl:items-start">
        <OffseasonLogo className="w-[292px] h-[90px]" />
        <div className="text-brand-mint text-2xl uppercase font-prohibition">
          For athletes. By athletes.
        </div>
      </div>
      <div className="flex-1 flex sm:flex-row flex-col sm:justify-between xl:justify-end lg:justify-center md:justify-around gap-2 xl:w-fit w-full lg:gap-[160px] xl:gap-[60px] 2xl:gap-[160px] xl:mt-0 mt-14">
        <div className="flex flex-col items-start justify-start gap-4">
          <div className=" leading-[32px] font-semibold text-white text-2xl">Athletes</div>
          <div className="flex flex-col items-start justify-start text-base text-slate-300">
            <Link
              className="leading-[24px] text-[inherit] [text-decoration:none]"
              href={Route.FresnoAthletes}
            >
              Fresno, CA
            </Link>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-4">
          <div className=" leading-[32px] font-semibold text-white text-2xl">Support</div>
          <div className="flex flex-col items-start justify-start text-base text-slate-300">
            <ExternalLink
              className=" leading-[24px] text-[inherit] [text-decoration:none]"
              href={`mailto:${OFFSEASON_SUPPORT_EMAIL}?subject=Support`}
              target="_blank"
              rel="noreferrer"
            >
              {OFFSEASON_SUPPORT_EMAIL}
            </ExternalLink>
          </div>
        </div>
        <div className="flex flex-col items-start justify-start gap-4 text-white shrink-0">
          <div className="font-semibold text-2xl">Follow us</div>
          <div className="flex flex-row items-start justify-start gap-4">
            <ExternalLink href={INSTAGRAM_URL}>
              <InstagramIcon width="21" height="21" />
            </ExternalLink>
            <ExternalLink href={FACEBOOK_URL}>
              <FacebookIcon width="21" height="21" />
            </ExternalLink>
          </div>
        </div>
      </div>
    </div>
    <div className="w-full flex flex-col items-start justify-start md:gap-10 gap-6 max-w-[1640px] text-left text-sm text-slate-300 border-t-slate-800 border-t-2">
      <div className="self-stretch flex md:pt-10 pt-6 flex-row items-start sm:gap-4 gap-2 justify-between">
        <div className=" leading-[20px] font-medium">© 2024 The Offseason</div>
        <div className="flex sm:flex-row flex-col sm:items-start items-end justify-start sm:gap-4 gap-1 text-right">
          {LINKS.map(({ title, href }) => (
            <Link
              key={title}
              href={href}
              target="_blank"
              className={cn(
                buttonVariants({ variant: 'link', colorType: 'white' }),
                'text-slate-300 text-[14px] h-fit',
              )}
            >
              {title}
            </Link>
          ))}
        </div>
      </div>
    </div>
  </div>
)

export default Footer
