import { SVGProps } from 'react'

export const FlagIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M5 14C5.93464 13.0838 7.19124 12.5707 8.5 12.5707C9.80876 12.5707 11.0654 13.0838 12 14C12.9346 14.9161 14.1912 15.4293 15.5 15.4293C16.8088 15.4293 18.0654 14.9161 19 14V4.99996C18.0654 5.9161 16.8088 6.42925 15.5 6.42925C14.1912 6.42925 12.9346 5.9161 12 4.99996C11.0654 4.08383 9.80876 3.57068 8.5 3.57068C7.19124 3.57068 5.93464 4.08383 5 4.99996V14ZM5 14V21"
      stroke="black"
      strokeWidth="1.8"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
